module.exports = {
    header_bar:{
        text:"From Nature to Skin to Nature",
        tencel:"{'@'}tencel_luxe"
    },
    header_menu:{
        logout: "sign out"
    },
    footer:{
        newsletter:{
            header:"Join our Newsletter",
            description:"For updates, offers and mind expanding info enter your email below.",
            placeholderName:"Name",
            placeholderEmailAddress:"Email",
            placeholderEmailAddressConfirm:"CONFIRM EMAIL ADDRESS*",
            agreeText:"I consent to TENCEL™ LUXE processing my personal data in order to send me personalized marketing material.",
            buttonText:"Submit",
            tipText:"*MANDATORY FIELD"
        }
    },
    newsletter:{
        header:"Join our Newsletter",
        description:"For updates, offers and mind expanding info enter your email below.",
        placeholderName:"Name*",
        placeholderEmailAddress:"Email Address*",
        placeholderEmailAddressConfirm:"CONFIRM EMAIL ADDRESS*",
        agreeText:"I consent to TENCEL™ LUXE processing my personal data in order to send me personalized marketing material.",
        buttonText:"SUBMIT",
        tipText:"*MANDATORY FIELD"
    },
    contact:{
        header:"Contact Us"
    },
    about:{
        pageTitle:"ABOUT TENCEL™LUXE"
    },
    sustainability:{
        pageTitle:"SUSTAINABILITY"
    },
    qualities:{
        pageTitle:"QUALITIES",
        appTitle:"TENCEL™ LUXE \nApplications"
    },
    stories:{
        pageTitle:"STORIES",
        filter:"FILTER STORIES"
    },
    storiesDetails:{
        pageTitle:"STORY"
    }
}
