import { createRouter, createWebHistory } from 'vue-router'

const Layout = () => import( '@/views/Layout')
const Home = () => import( '@/views/home/Home')
const Contact = () => import( '@/views/contact/Contact')
const ThankYou = () => import( '@/views/contact/ThankYou')
const About = () => import( '@/views/about/About')
const Applications = () => import( '@/views/applications/Applications')
const Process = () => import( '@/views/process/Process')
const Sustainability = () => import( '@/views/sustainability/Sustainability')
const Qualities = () => import( '@/views/qualities/Qualities')
const Stories = () => import( '@/views/stories/Stories')
const Details = () => import( '@/views/stories/Details')
const Events = () => import( '@/views/events/Events')
const TradeShows = () => import( '@/views/trade-shows/TradeShows')
const Newsletter = () => import( '@/views/newsletter/Newsletter')
//路由数组
const routes = [
    {
        path: "/",
        component: Layout,
        children:[
            {
                name:'Home',
                path: '/',
                redirect: '',
                component:Home
            },
            {
                name:'About',
                path: '/about',
                component:About
            },
            {
                name:'Process',
                path: '/about/process',
                component:Process
            },
            {
                name:'Sustainability',
                path: '/about/sustainability',
                component:Sustainability
            },
            {
                name:'Qualities',
                path: '/about/qualities',
                component:Qualities
            },
            {
                name:'Applications',
                path: '/about/applications',
                component:Applications
            },
            {
                name:'Stories',
                path: '/stories/:type?',
                component:Stories
            },
            {
                name:'Details',
                path: '/stories/:type/:title',
                component:Details
            },
            {
                name:'Events',
                path: '/meetus/events',
                component:Events
            },
            {
                name:'TradeShows',
                path: '/meetus/trade-shows',
                component:TradeShows
            },
            {
                name:'Contact',
                path: '/contact',
                component:Contact
            },
			{
                name:'ThankYou',
                path: '/thank-you-for-staying-connected',
                component:ThankYou
            },
			{
                name:'Newsletter',
                path: '/newsletter',
                component:Newsletter
            }
        ]
    },
	{
		path: '/:pathMatch(.*)',
		redirect: '/'
	}
]

//路由对象
/*
* createWebHashHistory
* createWebHistory
* */
const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes //上面的路由数组

})

router.beforeEach((to,from,next) => {
    if(to.path != from.path){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    //if (to.matched.length == 0) { router.push(to.path); }
    next();
})

//导出路由对象，在main.js中引用
export default router
