import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import lazyPlugin from 'vue3-lazy'
import './assets/css/base.css'
import 'element-plus/dist/index.css'

import VueI18n from './language'


createApp(App)
    .use(router)
    .use(lazyPlugin,{})
    .use(VueI18n)
    .mount('#app')
